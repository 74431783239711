.notification {
  padding: 10pt 25pt;
  position: fixed;
  top: -100pt;
  right: 15pt;
  z-index: 100;
  width: auto;
  max-width: min(360pt, 70vw);
  border-radius: 5pt;
  transition: top 0.5s ease-in-out;
}

.notification_text {
  font-size: 14pt;
  color: rgb(105, 105, 105);
  font-weight: bold;
  margin-bottom: 1rem;
}

.notification_icon {
  font-size: 16pt;
  margin-right: 1rem;
}

.notification_title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.notification_container {
  padding: 0.5rem;
}

.notification_desc, .notification_date {
  text-align: left;
}